<template>
  <div>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

      <b-row class="mb-3" align-h="between">
        <b-col cols="auto"></b-col>
        <b-col cols="auto">
          <!-- <b-button variant="outline-primary" @click="navigate('Users.Addresses.Add')"><b-icon icon="plus"/> {{ $t('views.users.addresses.button.addaddress') }}</b-button> -->
        </b-col>
      </b-row>

   <div>
    <b-alert show>Wird jeweils am Morgen, um 05:00 Uhr neu berechnet</b-alert>
    <h3>Einträge mit Unterschiede</h3>
    <p>Nachfolgende Einträge unterscheiden sich zwischen ClickUp und Absence</p>
<b-table striped hover :items="filteredReports" :fields="fields">
    <template v-slot:cell(date)="data">
      <timeago :datetime="data.item.date"/>
    </template>
  </b-table>
    <h3>Alle Einträge</h3>
    <p>Die nachfolgenden Einträge wurden für die Abrechnung ignoriert.</p>
      <b-table striped hover :items="this.reports" :fields="fields">
        <template v-slot:cell(date)="data">
          <timeago :datetime="data.item.date"/>
        </template>
          </b-table>
          </div>
  </div>
</template>

<script>
import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import { TableHelper } from '@/assets/js/helper/table'

import MixinEntityBase from '@/components/mixins/EntityBase'
// import EntityEntryTable from '@/components/entity/EntryTable'
import http from '@/$plugins/http'

const ENTITY_KEY = 'users'

export default {
  name: 'Users.ClickupAbsenceCheck',
  mixins: [MixinEntityBase],
  components: {
    // EntityEntryTable
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      loading: false,
      reports: [],
      summaryreports: [],
      emailFilter: '',
      accountableFilter: false,
      dateFilter: null,
      accountingTaskFilter: '',
      isSelectedFilter: false,
      fields: [
        { key: 'date', label: 'Datum', sortable: true },
        { key: 'hoursInClickUp', label: 'In Clickup', sortable: true },
        { key: 'hoursInAbsence', label: 'In Absence', sortable: true }
      ],
      summaryreportsfields: [
        { key: 'accountingTaskName', label: 'Taskname', sortable: true },
        { key: 'hoursPrepared', label: 'Stunden', sortable: true, formatter: (value, key, item) => this.formatNumber(value), class: 'text-right' },
        { key: 'moneyPrepared', label: '$$$', sortable: true, formatter: (value, key, item) => this.formatMoney(value), class: 'text-right' },
        { key: 'remainingFromBudget', label: 'Noch abrechenbar', formatter: (value, key, item) => this.formatNumber(value), sortable: true, class: 'text-right' }
      ]
    }
  },
  computed: {
    isCurrentRoute () {
      return Object.values(this.$route.matched[this.$route.matched.length - 1].components).find(c => c.name === this.$options.name) !== undefined
    },
    filteredReports () {
      return this.reports.filter(report => report.hoursInClickUp !== report.hoursInAbsence)
    }
  },
  methods: {
    canBePrepared (entry) {
      return entry.accountingTask.name !== this.worklogsNotEditable
    },
    formatMinutesToHoursMinutes (minutes) {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours}h ${remainingMinutes}m`
    },
    async loadReport () {
      try {
        this.loading = true
        const response = await http.get(`/${STOREMODULE_ENDPOINTMODULE_MAP.employee}/api/${STOREMODULE_ENDPOINT_MAP.timetrackingcrosscheck}/byuserid/${this.userId}`)
        this.reports = response.data.result.trackingData
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    const urlParams = new URLSearchParams(window.location.search)
    this.currentPage = parseInt(urlParams.get('page')) || 1
    this.pageSize = parseInt(urlParams.get('pageSize')) || 50
    const urlParts = window.location.pathname.split('/')
    const userId = urlParts[urlParts.length - 2] // Extract the second-to-last part from the URL path
    this.userId = userId
    this.loadReport()
  }
}
</script>

<style lang="scss">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
